import React, { useEffect, useState } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import { Product } from '@Types/product/Product';
import Head from 'next/head';
import { SitelinksConfiguration } from '@Types/project/SitelinksConfiguration';
import Cookies from 'cookies';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { createClient, LocaleStorage, useDarkMode, useProject } from 'frontastic';
import { FrontasticRenderer } from 'frontastic/lib/renderer';
import { tastics } from 'frontastic/tastics';
import { ResponseHandler } from '../frontastic/lib/utils/ResponseHandler';
import { Log } from '../helpers/errorLogger';
import { ServerSideProps } from '../helpers/utils/serverSideProps';
import styles from './slug.module.css';

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
};

export default function Slug({ data, locale }: SlugProps) {
  LocaleStorage.locale = locale;

  const { applyTheme } = useDarkMode();
  const { getSiteLinksConfiguration } = useProject();
  const [siteLinksConfiguration, setSiteLinksConfiguration] = useState<SitelinksConfiguration>();

  useEffect(() => {
    applyTheme(data?.pageFolder?.configuration?.theme);
    getSiteLinksConfiguration().then((siteLinksConfiguration) => {
      setSiteLinksConfiguration(siteLinksConfiguration);
    });
  }, [data?.pageFolder?.configuration]);

  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  if (!data!.ok && data!.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data!.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  const isHomepage = data?.pageFolder.configuration?.path === '/';

  return (
    <>
      {isHomepage && (
        <Head>
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "${siteLinksConfiguration?.name}",
              "url": "${siteLinksConfiguration?.url}",
              "potentialAction": {
                "@type": "SearchAction",
                "target": { "@type": "EntryPoint", "urlTemplate": "${siteLinksConfiguration?.urlTemplate}" },
                "query-input": "required name=search_term_string"
              }
            }`}
          </script>
        </Head>
      )}
      <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({ params, locale, query, req, res }) => {
  LocaleStorage.locale = locale;

  const frontastic = createClient();
  const data = await frontastic.getRouteData(params, locale, query, req, res);
  const status = ServerSideProps.getRouteDataStatus(data);

  if (data?.['page']) {
    data['page'].shouldIndex = true;

    const pathsToExclude = ['/search', '/account', '/cart', '/checkout', '/thank-you'];
    const isPathExcluded = pathsToExclude.some((path) => req.url?.includes(path));
    if (isPathExcluded || query?.cursor) {
      data['page'].shouldIndex = false;
    }

    const { dataSources } = data['data'];
    const productSource = dataSources['__master'] || dataSources;
    const product: Product | null = productSource['product'];
    if (product) {
      const sku = params?.slug[2];
      const stockStatus = product.variants.some((variant) => variant.sku === sku && variant.isOnStock);
      if (!stockStatus) {
        res.statusCode = 404;
        data['page'].shouldIndex = false;
      }
    }
  }

  if (status === 'IS_ERROR') {
    Log.error('Error retrieving data: ', data);
  }

  if (status === 'NOT_FOUND' || status === 'IS_ERROR') {
    const notFoundPage = await frontastic.getRouteData(
      ServerSideProps.getParamsForNotFoundRoute(),
      locale,
      ServerSideProps.getQueryParamsForNotFoundRoute(query),
      req,
      res,
    );

    if (ServerSideProps.getRouteDataStatus(notFoundPage) === 'IS_VALID') {
      res.statusCode = 404;
      return {
        props: {
          data: notFoundPage || null,
          locale: locale,
          ...(await serverSideTranslations(locale, ServerSideProps.getNamespaces())),
        },
      };
    }

    return { notFound: true };
  }

  if (status === 'REDIRECT') {
    const destination = (data as object)['target'];
    let statusCode = (data as object)['statusCode'];

    if (statusCode === 300) {
      new Cookies(req, res).set('last-redirect', destination, { httpOnly: false, overwrite: true });
      statusCode = 301;
    }

    return { redirect: { destination, statusCode } as Redirect };
  }

  if (status === 'IS_STRING') {
    return { props: { data: { error: data }, error: data } };
  }

  return {
    props: {
      data: data || null,
      locale: locale,
      ...(await serverSideTranslations(locale, ServerSideProps.getNamespaces())),
    },
  };
};
